export default {
    type: 'Graph',
    filter_keys: [
        ['Filter', 'sales-and-quantity']
    ],
    graph_settings: {
        grid: {left: '100rem'},
        x_axis_label_interval: 'auto',
        y_axis_name_gap: 35
    },
    id: 'sales-and-quantity-trend'
}
